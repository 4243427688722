/**
 * LayoutWhite component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import AOS from 'aos';
import 'aos/dist/aos.css';

import '../styles/styles.css';

import HeaderNav from "../components/headerNavEn"
import Footer from "../components/footerEn"
import Seo from "../components/seo"


const LayoutWhiteEn = ({ children, title, subTitle, seccion, linkTo }) => {
  
  React.useEffect(() => {
	  AOS.init({
		  once: true
	  });
  }, []);

  return (
    <>      

		<Seo 
			title={title + " " +subTitle}
		></Seo>
		<div className={"container-fluid "+seccion}>
			<div id="header" className={"white "+seccion}>
				<div>
					<HeaderNav color={true} seccion={seccion} />
					{children}
				</div>
			</div>
			<Footer />
		</div>
    </>
  )
}

LayoutWhiteEn.propTypes = {
  children: PropTypes.node.isRequired,
}
LayoutWhiteEn.defaultProps = {
  title: ``,
  subTitle: ``,
  seccion: ``,
  linkTo: ``
}
export default LayoutWhiteEn
