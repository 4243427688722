import * as React from "react"

import LayoutWhite from "../components/layoutWhiteEn"

const PreguntasFrecuentesPage = () => (
  <LayoutWhite
	seccion="preguntasFrecuentes"	
	title="FAQ"	
	linkTo="experienciaContainer"
	>		
			<div id="whiteContainer" className="row">
				<div className="col-12"
						data-aos='fade-up'
						data-aos-duration="600" data-aos-once="true">
					<h1 className="">FAQ</h1>
					
					<p className="pregunta">What is an international courier?</p>
					<p className="respuesta">The Courier service consists of the shipment of documents or packages of a limited size and/ or weight at a certain cost. It operates under the premium standard in postal or parcel services, which is used by companies that consider the transportation of their shipments to be of extreme importance, with a good value for money.</p>

					<p className="pregunta">Is it possible to consolidate different vendors in the same port of origin?</p>
					<p className="respuesta">Yes, and it is an advantage, because the importer makes a single shipment, keeps the goods from being handled and makes your business more profitable.</p>

					<p className="pregunta">What is the maximum volume of loose cargo?</p>
					<p className="respuesta">There is no limit, although the client must know that there is a cut-off volume resulting from the division of the value of a full container versus the value of 1 cbm from the same origin. This cut-off volume determines whether it is more profitable to load in loose cargo or in a full container condition.</p>

					<p className="pregunta">Regarding air shipments, what does gauged weight mean?</p>
					<p className="respuesta">The "gauged weight" of the cargo is the total volume multiplied by 167. The proportion is as follows: 1 cbm = 167 kgs. Consequently, if the result gives a value greater than the total gross weight of the cargo, we use that value... Otherwise, we use the total gross weight.</p>
					
                    <p className="pregunta">What is a manifest?</p>
					<p className="respuesta">It is a document that details the entire cargo of a transport (brand, number, kind of goods, kilos, shippers, consignee). It shall be presented by the carrier to the customs or consular authorities that request it.</p>

					<p className="pregunta">What is International Multimodal Transport?</p>
					<p className="respuesta">It is the carriage of goods by two or more different modes of transport (truck, railcar, ship, air). In accordance with the contract of carriage, the multimodal operator takes the goods under their responsibility and custody to deliver them to another place located in a different country.<br/>
                    This system differs from traditional segmented transport because it is a system based on a "single contract" and secondly because the multimodal transport operator assumes responsibility for the enforcement of the contract as "principal" and not as an agent of the user or shipper. This principle is based on the " door to door" transport service of the goods, significantly reducing documentation and compliance with other formalities as in traditional transport.</p>
					
                    <p className="pregunta">What is an EXW condition?</p>
					<p className="respuesta">Ex-works, ex-factory, ex-warehouse, ex-mill. The seller has fulfilled their obligation to deliver by making the goods available to the buyer at their factory or workshop. They are neither responsible for loading the goods on the vehicle provided by the buyer nor for clearing them through customs for export, unless it is agreed differently. The buyer assumes all costs and risks of removing the goods from the seller's premises to their final destination.</p>

					<p className="pregunta">What is an FCA condition?</p>
					<p className="respuesta">It is a Free Carrier. The seller fulfills their obligation by placing the goods at the designated location, at the carrier's expense, after the goods have been cleared through customs for export. If the buyer has not fixed any specific location, the seller may choose within the stipulated area the point where the carrier will take charge of the goods. This term can be used with any mode of transport, including multimodal.</p>

				</div>			
			</div>
  </LayoutWhite>
)

export default PreguntasFrecuentesPage